<!--
* @Author: DY
* @Date: 2020年11月27日10:31:40
* @Description: '合同转换（临时转正式），设置合同转换生效时间弹框  [销售管理-合同管理-正式合同台账-转换]'
-->
<template>
    <div class="contractConversion">
        <div class="content_box">
            <div class="selectTime">
                <span class="demonstration">启用时间：</span>
                <el-date-picker
                    v-model="enable_time"
                    type="datetime"
                    placeholder="选择生效时间"
                    default-time="12:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </div>
            <el-divider>合同转换验证数据</el-divider>
            <div class="dataTable">
                <table>
                    <tr>
                        <th>原合同编号</th>
                        <td colspan="3">
                            {{ validationData.old_contract_number }}
                        </td>
                    </tr>
                    <tr>
                        <th>原合同未完成的任务</th>
                        <td>
                            {{ validationData.unfinished_task }}
                        </td>
                    </tr>
                    <tr>
                        <th>原合同未完成的订单</th>
                        <td>
                            {{ validationData.unfinished_order }}
                        </td>
                    </tr>
                    <tr>
                        <th>是否管控</th>
                        <td colspan="3">
                            <span v-if="validationData.is_control">已管控</span>
                            <span v-else>未管控</span>
                        </td>
                    </tr>
                    <tr>
                        <th>管控内容</th>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <td colspan="3" v-html="validationData.control_msg"></td>
                    </tr>
                </table>
            </div>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="confirm">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: 'contract-conversion',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 验证数据
            validationData: {},
            // 生效时间
            enable_time: '',
            // 合同编号
            contract_number: '',
        };
    },
    watch: {},
    computed: {},
    created() {
        this.enable_time = this.extr.enable_time;
        this.contract_number = this.extr.contract_number;

        // 获取验证数据
        this.getValidationData();
    },
    mounted() {},
    methods: {
        // 获取验证数据
        getValidationData() {
            this.$axios
                .get(`/interfaceApi/sale/contract_transition/get_transition_check_data?contract_number=${this.contract_number}`)
                .then(res => {
                    this.validationData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 取消
        cancel() {
            this.$parent.hide();
        },
        // 确定
        confirm() {
            this.$axios
                .post('/interfaceApi/sale/contract_transition/enable_transition_contract', {
                    contract_number: this.contract_number,
                    EnableTime: this.enable_time,
                })
                .then(res => {
                    if (res && res !== '') {
                        this.$message({
                            message: res,
                            type: 'warning',
                        });
                    } else {
                        this.$message({
                            message: '转换成功！',
                            type: 'success',
                        });
                    }
                    this.$parent.hide();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>

<style scoped lang="stylus">
.contractConversion
    position relative
    height 100%
    .content_box
        padding 0.15rem
        height calc(100% - 0.6rem)
        .dataTable
            height calc(100% - 0.9rem)
            overflow-y auto
            table
                width 100%
                border-collapse collapse
                th,td
                    border 1px solid #ccc
                    font-size 0.16rem
                    font-weight 400
                    line-height 0.36rem
                th
                    width 2rem
                    background #f8fafd
                td
                    text-indent 0.1rem
    footer
        width 100%
        position absolute
        bottom 0.2rem
        left 0
        text-align center
        .el-button
            width 1.5rem
            padding 0
            font-size 0.16rem
            line-height 0.4rem
            margin 0 0.2rem
            height 0.4rem
</style>
